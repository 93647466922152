import CryptoJS from 'crypto-js'

const Key = '8GIA8dWuAhjQKiBHfkELxcZXgmOjZ4xi'
const IV = '2dpsxlzp0kc3qic6'

export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },
    
    //加密
    encrypt(word, keyStr = Key, iv = IV) {
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var iv = CryptoJS.enc.Utf8.parse(iv)
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv });
        return encrypted.toString();
    },
    //解密
    decrypt(word, keyStr = key, iv = IV) {
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var iv = CryptoJS.enc.Utf8.parse(iv)
        var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
 
}